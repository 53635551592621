import * as React from 'react';
import { useEffect } from 'react';
import { useRouteData } from 'react-static';
import { pushToDataLayer } from '../../common/helpers/dataLayer';
import { toKey } from '../../common/helpers/strings';
import { useDebug } from '../../common/hooks/useDebug';
import { useTranslations } from '../../common/translation';
import { BannerCarousel } from '../../components/banner-carousel/BannerCarousel';
import { FeaturedCarousel } from '../../components/featured-carousel/FeaturedCarousel';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { DefaultLayout } from '../default/DefaultLayout';
import { LandingPageType, TitleLandingLayoutProps } from './TitleLandingLayout.props';
import translations from './translations';

// TODO: Add SEO props
const seo = {
  description: '',
};

const getTranslationArgs = (type: LandingPageType): [string, string] => {
  switch (type) {
    case 'Movies':
      return ['moviesLandingTitle', 'Movies'];
    case 'TV':
      return ['tvLandingTitle', 'TV'];
    case 'Games & Apps':
      return ['gamesAndAppsLandingTitle', 'Games and Apps'];
    default:
      return ['', ''];
  }
};

// noinspection JSUnusedGlobalSymbols
export default () => {
  const { bannerCarouselProps, featuredQueues, locale, translatedPaths, type } = useRouteData<
    TitleLandingLayoutProps
  >();
  const { t } = useTranslations(translations, locale);
  const title = t(...getTranslationArgs(type));

  useDebug('app:layout:TitleLandingLayout', { props: { bannerCarouselProps, featuredQueues, locale, type } });

  useEffect(() => {
    pushToDataLayer({ title });
  }, []);

  return (
    <DefaultLayout key={toKey(`${locale}-${type}-landing`)} locale={locale} seo={seo} translatedPaths={translatedPaths}>
      <HeadTitle title={title} />
      <BannerCarousel {...bannerCarouselProps} />
      {(featuredQueues || [])
        .filter((queue) => queue && queue.queueStatus)
        .map((queue) => (
          <FeaturedCarousel headingTag='h1' key={queue.baseQueueName} {...queue} />
        ))}
    </DefaultLayout>
  );
};
