import * as React from 'react';
import { buildQueueName } from '../../common/helpers/carousel';
import { useGet } from '../../common/hooks/useGet';
import { useLocale } from '../../common/hooks/useLocale';
import { TitleCarousel } from '../title-carousel/TitleCarousel';
import { FeaturedCarouselProps } from './FeaturedCarousel.props';

/*
 * This provides the perfect example for why a hook is a better choice than a HOC for the AJAX call,
 * due to the requirement of manipulating the AJAX result before rendering.
 */
export const FeaturedCarousel = ({ baseQueueName, headingTag }: FeaturedCarouselProps) => {
  const locale = useLocale();
  const queueName = buildQueueName(baseQueueName, locale);

  const { data, loading } = useGet(`/queues/${queueName}.json`, {}, false, false);

  const nodes = data.nodes || [];
  const title = data.title || '';

  if (loading || !nodes.length) {
    return null;
  }

  return <TitleCarousel headingTag={headingTag} lazyLoad={true} nodes={nodes} title={title} />;
};
